import React, { useEffect, useState } from "react";
import styles from './ProductPriceLoyalityTag.module.scss';
import { connect } from "react-redux";
import { __getImage } from "factory/modules/HK_service";
import { __renderNextRoleBasedPrice } from "../Loyality_Service";
import LoyaltyInfoPopup from "../PDP/LoyalityInfoPopup";
import UniversalAnalytics from "factory/modules/UniversalAnalytics";
import config from "factory/modules/config";
import HK from "factory/modules/HK";

const ProductPriceLoyalityTag = (props) => {
    const [nextRolePricingObj, setNextRolePricingObj] = useState(null);
    const [addPremiumPopup, setAddPremiumPopup] = useState(false)

    useEffect(() => {
        if (props._CARTReducer || props._userRoleReducer) {
            const NextRolePricing = __renderNextRoleBasedPrice(props.hkUserLoyaltyPricingDto, props._CARTReducer, props._userRoleReducer);
            setNextRolePricingObj(NextRolePricing);
        }
    }, [props._CARTReducer, props._userRoleReducer])

    const handleLoyaltyClick = () => {
        let data = {
            event_name: 'select_promotion',
            'reward_points_available': '',
            'value': '',
            'promotion_name': '',
            'creative_name': '',
            'creative_slot': '',
            items: [
                {
                    'promotion_name': 'Loyalty Feature',
                    'PDPprice_click': props.data && props.data.offer_pr || '',
                    'Brand_name': props.data && props.data.brName || '',
                    'variant_name': props.data && (props.data.spName || props.data.nm),
                    'variant_category': props.data && props.data.catName || '',
                }
            ]
        }
        UniversalAnalytics.LoyaltyDataLayer(data);
    }

    const createTempUser = () => {
        if (!(localStorage.getItem('isUserLoggedIn') !== null && localStorage.getItem('isUserLoggedIn') !== 'false' && HK.Cookies.get('authtoken'))) {
            HK.loader(true)
            let reqObj = {
                method: 'get',
                url: config.APIS.userFlow.tempUserCreate
            }
            HK.ajaxCall(reqObj).then((res) => {
                const _res = res && res.data && res.data.results
                if (_res && !_res.exception) {
                    HK.Cookies.set('authtoken', '"' + _res.authToken + '"', 365);
                    localStorage.setItem('isUserLoggedIn', _res.user.id);
                    localStorage.setItem('isTMPUser', 1);
                    HK.setCookieLoggedIn(_res.user.id, 1);
                    setAddPremiumPopup(true);
                }
            }).catch(err => {
                HK.errorPopup("Something went wrong, please try again.", 'Got: exception::' + reqObj.url);
                HK.reportErrorToDashboard({
                    stackTrace: new Error().stack,
                    errorMessage: err
                });
            }).finally(() =>{
                HK.loader(false)
            })
        }
        else {
            setAddPremiumPopup(true);
        }
    }

    if (nextRolePricingObj && nextRolePricingObj.price) {
        return (
            <div className={`${props.pageType === 'PDP' ? styles['NoMargin'] : ''} ${styles['loyality-product-price-container']}`}>
                <img src={__getImage('/loyalty/Loyalty_.svg')} alt="Loyalty_" className={styles['loyalty_icon']} />
                <div className={styles['amount-ttl']}>
                    Get it for
                </div>
                <div className={styles['amnt']}>
                    ₹{nextRolePricingObj.price}
                </div>
                {
                    props.pageType === 'PDP' ?
                        <img src={__getImage('/icons/revamp/info-icon.svg')} className={styles['info-icon']} alt="info-icon" onClick={() => { handleLoyaltyClick(); createTempUser() }} />
                        : <></>
                }
                {
                    addPremiumPopup ?
                        <LoyaltyInfoPopup setAddPremiumPopup={setAddPremiumPopup} />
                        : <></>
                }
            </div>
        )
    } else {
        return <></>
    }
}

function mapStateToProps(state) {
    return {
        _CARTReducer: state.CARTReducer,
        _userRoleReducer: state.userRoleReducer

    }
}

export default connect(mapStateToProps, null)(ProductPriceLoyalityTag);