//React:::::::::::
import React, { useEffect, useState } from 'react';

//Styles:::::::::::
import styles from "components/ReviewData/ReviewHeader/reviewHeader.module.scss";
import { convertToModule } from 'factory/utils/clientUtils';

//Components:::::::::::::
import IntersectionObserver from 'components/common/IntersectionObserver';

//Services and Methods::::::::::::::
import HK from 'factory/modules/HK';

//Analytics::::::::::::::::::
import Analytics from 'factory/modules/Analytics';
import UniversalAnalytics from '../../../factory/modules/UniversalAnalytics';

//Store and Actions:::::::::::::
import { withRouter } from 'next/router';
import { connect } from 'react-redux';
import { isLoginPopupAction } from 'components/common/login/action';
import PDPEventTracker from '../../../components/Pdp/common/PDPEventTracker';
import { CARTAction } from 'components/common/header/action.js';

const BuyNow = ({ ...props }) => {
    const [buyNowPostLoginFlag, set_buyNowPostLoginFlag] = useState(false);
    useEffect(() => {
        if (props.isLoggedin && buyNowPostLoginFlag) {
            _doBuyNowCall();
        }
    }, [props.isLoggedin && buyNowPostLoginFlag]);
    const doCall = (event, eventName, eventCat, eventAction, eventLabel) => {
        event.stopPropagation();
        event.preventDefault();
        localStorage.removeItem('packInfo');
        if (props.isPack) {
            let packdata = {
                packId: props.id,
                storeVariantIdsInPackStr: props.storeVariantId,
                quantity: props.quantity || 1
            };
            localStorage.setItem('packInfo', JSON.stringify(packdata));
        }
        if (!props.isLoggedin) {
            set_buyNowPostLoginFlag(true);
            props.dispatch(isLoginPopupAction({
                isShow: true,
                activeTab: 'login'
            }));
        } else _doBuyNowCall();
        let data = {
            'event': eventName,
            'eventCategory': eventCat,
            'eventAction': eventAction,
            'eventLabel': eventLabel,
        };
        HK._setDataLayer(data);
    };
    const _doBuyNowCall = () => {
        let url = props.isPack ? HK.config.APIS.cart.buyPack : HK.config.APIS.cart.buyNow;
        HK.loader(true);
        let data;
        if (props.isPack) {
            data = {
                packId: props.id,
                storeVariantIdsInPackStr: props.storeVariantId,
                quantity: props.quantity || 1,
                userId: HK.loggedIn()
            };
        }
        else {
            data = {
                storeVariantId: props.id,
                vendorId: props.vendorID,
                quantity: props.quantity || 1,
                overrideQty: false,
                expressCheckout: true,
                userId: HK.loggedIn()
            };
        }
        HK.ajaxCall({
            url: url,
            method: 'post',
            data
        }).then(res => {
            if (res && res.data && res.data.results && !res.data.results.exception) {
                props.dispatch(CARTAction(res.data.results.cartSummary));
                Analytics.Scope().buyNowAddTocart(props.varData, 'BuyNow', props.quantity || 1);
                UniversalAnalytics.PRESERVE_CLICKSTREAM_TO_STORAGE('BuyNow', props.varData);
                Analytics.dataLayerForCartUpdate(props.id, props.varData, props.isPack, "reactCartUpdateEvent", true);
                let loc = '/checkout?cartId=' + res.data.results.cartId + '&selectedAddressId=' + res.data.results.addressId + '&expressCheckout=true';
                if (props.isBuyNowFromCompare) {
                    props.isBuyNowFromCompare();
                }
                const clickStream = {
                    widgetName: 'Buy Now Click',
                    eventName: "BUY_NOW_CLICK",
                    brand: props.varData && props.varData.brName || "",
                    category: props.varData && props.varData.category || "",
                    itemName: props.varData && props.varData.name || "",
                    id: props.varData && props.varData.id || "",
                    offerPrice: props.varData && props.varData.offerPrice || 0,
                };
                if (props.eventData) {
                    UniversalAnalytics.PRESERVE_ON_RELOAD().BUY_NOW_EVENT({ ...props.eventData, ...{ quantity: props.quantity || 1 } });
                }
                if (props.customBuyNow && props.customBuyNow === true) {
                    if (typeof props.customBuyNowEvent === "function") {
                        let tempClickStream, clickStreamData;
                        if (props.customVarData) {
                            tempClickStream = {
                                id: props.customVarData.id ? props.customVarData.id : "",
                                itemName: props.customVarData.name ? props.customVarData.name : "",
                                brand: props.customVarData.brName ? props.customVarData.brName : "",
                                offerPrice: props.customVarData.offerPrice ? props.customVarData.offerPrice : 0,
                                itemPosition: props.customVarData.itemPosition || 1
                            };
                        }
                        else {
                            tempClickStream = {
                                id: clickStream.id,
                                itemName: clickStream.itemName,
                                brand: clickStream.brand,
                                offerPrice: clickStream.offerPrice,
                            };
                        }

                        if (props.customWidgetName && props.customWidgetName !== "") {
                            clickStreamData = {
                                ...tempClickStream,
                                widgetName: props.customWidgetName ? props.customWidgetName : ""
                            };
                        }
                        else {
                            clickStreamData = {
                                ...tempClickStream
                            };
                        }

                        props.customBuyNowEvent({
                            ...clickStreamData,
                            qty: 1
                        });
                    }
                }
                else {
                    UniversalAnalytics.PRESERVE_CLICKSTREAM_TO_STORAGE(HK.config.eventName.COMPONENT_CLICK, PDPEventTracker.getData(clickStream, props.reviewData));
                }
                window.location.href = loc;
                HK.loader();
            } else {
                HK.loader();
                HK.errorPopup('Could not process your request. Please try again later.', 'Got: exception::' + url);
                HK.reportErrorToDashboard({
                    stackTrace: new Error().stack,
                });
            }
        }).catch(err => {
            HK.loader();
            HK.errorPopup('Could not process your request. Please try again later.', 'Got: ' + err + '::' + url);
            HK.reportErrorToDashboard({
                stackTrace: new Error().stack,
                errorMessage: err
            });
        });
    };

    const triggerInViewEvent = (/*data*/) => {
        return (inView) => {
            if (!inView) return;
            try {
                const eventData = {
                    eventName: 'BUY_NOW',
                    widgetName: 'Buy Now',
                    brand: props.varData && props.varData.brName,
                    category: props.varData && props.varData.category,
                    itemName: props.varData && props.varData.name,
                    id: props.varData && props.varData.id,
                    offerPrice: props.varData && props.varData.offerPrice
                };
                HK.triggerComponentViewEvent(PDPEventTracker.getData(eventData, props.reviewData));
            } catch (err) {
                console.log(err);
            }
        };
    };
    return (
        <IntersectionObserver className={convertToModule(styles, "hk-elements--buyNow")} onChange={triggerInViewEvent(props)}>
            <button type="button" className={convertToModule(styles, 'track-add-to-cart addToCart tr-addPackToCart buyNownew ' + props.className)} onClick={(event) => doCall(event, props.event, props.eventCat, props.eventAct, props.varData.name)}>
                {!props.isImage ? null : <img src={HK.getImage('/icons/flash.svg')} />}
                {props.buttonDisplayName || (props.isCombo ? "Buy Combo" : props.isPdp ? HK.vernacular.variantNew_input_buynow[props.ln] : "Buy Now")}
            </button>
        </IntersectionObserver>
    );
};
function mapStateToProps(state) {
    return {
        isLoggedin: state.isLogedInReducer
    };
}
export default withRouter(
    connect(mapStateToProps, null)(BuyNow)
);