import config from "factory/modules/config";

export const __renderRoleBasedRender = (hkUserLoyaltyPricingDto, isPack, CARTReducer = {}, prodInfo) => {
    // premium user member
    if (
        hkUserLoyaltyPricingDto &&
        CARTReducer.hkUserLoyaltyDetailsDto &&
        (CARTReducer.hkUserLoyaltyDetailsDto.role === config.LOYALITY_ROLES.PREMIUM_USER)
    ) {
        return {
            icon: "",
            price: (hkUserLoyaltyPricingDto.hkPremiumOfferPrice),
            priceHead: "Premium Price",
            disc: hkUserLoyaltyPricingDto.hkPremiumDisc,
            hkCash: hkUserLoyaltyPricingDto.hkPremiumLoyaltyCash,
            role: CARTReducer.hkUserLoyaltyDetailsDto.role
        };
    }

    // select user member
    else if (hkUserLoyaltyPricingDto &&
        CARTReducer.hkUserLoyaltyDetailsDto &&
        (CARTReducer.hkUserLoyaltyDetailsDto.role === config.LOYALITY_ROLES.SELECT_USER)
    ) {
        return {
            icon: "",
            price: (hkUserLoyaltyPricingDto.hkSelectOfferPrice),
            priceHead: "Select Price",
            disc: hkUserLoyaltyPricingDto.hkSelectDisc,
            hkCash: hkUserLoyaltyPricingDto.hkSelectLoyaltyCash,
            role: CARTReducer.hkUserLoyaltyDetailsDto.role
        };
    }
    // normal user
    else {
        return {
            icon: "",
            price: ((hkUserLoyaltyPricingDto ? hkUserLoyaltyPricingDto.hkNormalOfferPrice : (isPack ? prodInfo.offer_pr  || prodInfo.offerPrice: prodInfo.offer_pr || prodInfo.offerPrice))),
            priceHead: "Sale Price",
            disc: (hkUserLoyaltyPricingDto ? hkUserLoyaltyPricingDto.hkNormalDisc : prodInfo.discount),
            hkCash: (hkUserLoyaltyPricingDto ? hkUserLoyaltyPricingDto.hkNormalLoyaltyCash : prodInfo.loyaltyCash),
            role: config.LOYALITY_ROLES.NORMAL_USER,
            premiumPrice: (hkUserLoyaltyPricingDto ? hkUserLoyaltyPricingDto.hkPremiumOfferPrice : "")
        };
    }
};

export const __getPLPItemPricing = (userRole, hkUserLoyaltyDetailsDto, productInfo) =>{    
    if (
        hkUserLoyaltyDetailsDto &&
        (userRole=== config.LOYALITY_ROLES.PREMIUM_USER)
    ) {
        return {
            icon: "",
            price: (hkUserLoyaltyDetailsDto.hkPremiumOfferPrice),
            priceHead: "Premium Price",
            disc: hkUserLoyaltyDetailsDto.hkPremiumDisc,
            hkCash: hkUserLoyaltyDetailsDto.hkPremiumLoyaltyCash,
            role: userRole
        };
    }

    // normal user
    else {
        return {
            icon: "",
            price: productInfo.offer_pr || productInfo.offerPrice,
            priceHead: "Sale Price",
            disc: productInfo.discount,
            hkCash: productInfo.loyaltyCash,
            role: config.LOYALITY_ROLES.NORMAL_USER,
        };
    }   
}


export const __renderNextRoleBasedPrice = (hkUserLoyaltyPricingDto, CARTReducer, _userRoleReducer) => {
    if ((CARTReducer && CARTReducer.hkUserLoyaltyDetailsDto || _userRoleReducer) && hkUserLoyaltyPricingDto) {

        if (
            hkUserLoyaltyPricingDto &&
            CARTReducer.hkUserLoyaltyDetailsDto &&
            (CARTReducer.hkUserLoyaltyDetailsDto.role === config.LOYALITY_ROLES.PREMIUM_USER || _userRoleReducer === config.LOYALITY_ROLES.PREMIUM_USER)
        ) {
            return {
                icon: "",
                price: (hkUserLoyaltyPricingDto.hkSelectOfferPrice),
                priceHead: "Select Price",
                disc: hkUserLoyaltyPricingDto.hkSelectDisc,
                hkCash: hkUserLoyaltyPricingDto.hkSelectLoyaltyCash,
                role: _userRoleReducer || (CARTReducer && CARTReducer.hkUserLoyaltyDetailsDto && CARTReducer.hkUserLoyaltyDetailsDto.role)
            };
        }

        // select user member
        else if (hkUserLoyaltyPricingDto &&
            CARTReducer.hkUserLoyaltyDetailsDto &&
            (CARTReducer.hkUserLoyaltyDetailsDto.role === config.LOYALITY_ROLES.SELECT_USER || _userRoleReducer === config.LOYALITY_ROLES.PREMIUM_USER)
        ) {
            return null
        }
        // normal user
        else {
            return {
                icon: "",
                price: (hkUserLoyaltyPricingDto.hkPremiumOfferPrice),
                priceHead: "Premium Price",
                disc: hkUserLoyaltyPricingDto.hkPremiumDisc,
                hkCash: hkUserLoyaltyPricingDto.hkPremiumLoyaltyCash,
                role: _userRoleReducer || (CARTReducer && CARTReducer.hkUserLoyaltyDetailsDto && CARTReducer.hkUserLoyaltyDetailsDto.role)
            };
        }
    } else {
        if(hkUserLoyaltyPricingDto){
            return  {
                icon: "",
                price: (hkUserLoyaltyPricingDto.hkPremiumOfferPrice),
                priceHead: "Premium Price",
                disc: hkUserLoyaltyPricingDto.hkPremiumDisc,
                hkCash: hkUserLoyaltyPricingDto.hkPremiumLoyaltyCash,
                // role: CARTReducer.hkUserLoyaltyDetailsDto.role
            };
        }else{
            return null
        }
    }
};

export const __getMemberCartObject = (_CARTReducer) => {
    let membershipObject = _CARTReducer && _CARTReducer.cartPricing && _CARTReducer.cartPricing.hkUserLoyaltyServiceProductDto && _CARTReducer.cartPricing.hkUserLoyaltyServiceProductDto.membershipStoreVariant;
    let netSavingsDueToMembershipProducts = _CARTReducer && _CARTReducer.cartPricing && _CARTReducer.cartPricing.hkUserLoyaltyServiceProductDto && _CARTReducer.cartPricing.hkUserLoyaltyServiceProductDto.netSavingsDueToMembershipProducts;
    if (membershipObject) {
        let tempCartObj = {
            id: membershipObject.id,
            vendorId: membershipObject.vendorId,
            name: membershipObject.nm,
            category: membershipObject.catName,
            offerPrice: membershipObject.offer_pr,
            brName: membershipObject.brName,
            img: membershipObject && membershipObject.pr_img && membershipObject.pr_img && membershipObject.pr_img.o_link || '',
            isConsultProduct: membershipObject.isConsultProduct,
            leafNode: membershipObject && membershipObject.catName || '',
            secondaryCategory: membershipObject && membershipObject.secondary_category_name || '',
            seccategory: membershipObject && membershipObject.secondary_category || '',
            netSavingsDueToMembershipProducts: netSavingsDueToMembershipProducts || '',
        };

        return tempCartObj;
    } else {
        return false;
    }
};

export const __checkMembershipProduct = (prdctData) => {
    let lotaltyProductCatName = 2;
    if (prdctData) {
        return (prdctData.sp_typ === lotaltyProductCatName || prdctData.isServiceType);
    }
    else
        return false;
}

export const __checkIfMemberShipProductAdded = (_CARTReducer) => {
    let tempMembershipObj = null;
    let cartData = _CARTReducer && _CARTReducer.cartPricing && _CARTReducer.cartPricing.cartVar;
    cartData && cartData.map(eachItem => {
        if (__checkMembershipProduct(eachItem)) {
            tempMembershipObj = eachItem;
        }
    });
    return tempMembershipObj;
};

export const __getPremiumBenefits = () => {
    const details = [
        'Flat 3% extra discount on all products',
        'Additional Saving on Launch Offer',
        'Free Delivery on all orders',

    ]
    return details;
}

export const __getCTALoyaltyValue = () => {
    const details = [
        'Unlock Free Delivery',
        '& get extra 3% OFF with Gritzo Loyalty'
    ]
    return details
}
